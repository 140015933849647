import React, { useState, useEffect } from 'react';
import { FlashMess, POST_API, Loader } from '../Helpers/Helpers';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { STRIPE_KEY } from "../Config";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(STRIPE_KEY);

const AffiliateSubscription = () => {
  const [plan, setPlan] = useState([]);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [showSubscriptionOptions, setShowSubscriptionOptions] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  
  const [loader, setLoader] = useState("hidden");
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  const [showConfirmPaymentModal,SetShowConfirmPaymentModal] = useState(false);

  useEffect(() => {
    //getSubscriptionPlan();
    getCurrentSubscription();
  }, []);

  const getSubscriptionPlan = () => {
    const formdata = new FormData();
    const request = {
      end_point: '/affiliate-plans',
      token: localStorage.getItem("gs_token"),
      formdata: formdata,
    };
    setLoader('visible');
    POST_API(request)
      .then((resp) => {
        setLoader('hidden');
        if (resp.status === 'success') {
          setPlan(resp.data);
        } else {
          setPlan([]);
        }
      })
      .catch((e) => {
        setLoader('hidden');
        setMsg("Subscription : " + e);
        setMsgType('danger');
      });
  };

  const getCurrentSubscription = () => {
    const formdata = new FormData();
    formdata.append('app_name', 'NEXGEN');
    const request = {
      end_point: '/get-current-subscription',
      token: localStorage.getItem("gs_token"),
      formdata: formdata,
    };
    setLoader('visible');
    POST_API(request)
      .then((resp) => {
        setLoader('hidden');
        if (resp.status === 'success') {
          setCurrentSubscription(resp.data);
        } else {
          setCurrentSubscription(null);
        }
      })
      .catch((e) => {
        setLoader('hidden');
        setMsg(e);
        setMsgType('danger');
      });
  };

  const handleClick = () => {
    setShowSubscriptionOptions(true);
    getSubscriptionPlan()
  };

  const handleCloseOptionsModal = () => {
    setShowSubscriptionOptions(false);
    setSelectedPlan(null);
  };

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const handleConfirmChange = () => {
    SetShowConfirmPaymentModal(true);
    setShowSubscriptionOptions(false);
  };

  const CanCancelSubsciption = (subscriptionId)=>{
    if(!subscriptionId || subscriptionId === "" || subscriptionId === undefined){
      return;
    }

    var formdata = new FormData();
      formdata.append('app_name', 'NEXGEN');
      formdata.append('subscriptionId', subscriptionId);
      let request = {
        end_point: '/can-cancel-subscription',
        token: localStorage.getItem("gs_token"),
        formdata: formdata,
      };
      setLoader('visible');
      let result = POST_API(request)
      .then((resp) => {
        setLoader('hidden');

        if(resp.status != "success"){
          setMsg(resp.message);
          setMsgType('danger');
          return;
        }
    
        let canCancel = true;
        if(resp.message == "SHOW_ALERT"){
          if(!window.confirm("Subscription is in the middle of the month, if you will cancel subscription now you won't get any refund")){
            canCancel = false
          }
        }
        if(canCancel){
         cancelSubsciption(subscriptionId);
        }
        
      })
      .catch((e) => {
        setLoader('hidden');
        console.log("Check Subscription error",e)
        return false;
      });

  }

  const cancelSubsciption = (subscriptionId)=>{
    if(!subscriptionId || subscriptionId === "" || subscriptionId === undefined){
      return;
    }
    
    var formdata = new FormData();
      formdata.append('app_name', 'NEXGEN');
      formdata.append('subscriptionId', subscriptionId);
      let request = {
        end_point: '/cancel-subscription',
        token: localStorage.getItem("gs_token"),
        formdata: formdata,
      };
      setLoader('visible');
      POST_API(request)
      .then((resp) => {
        setLoader('hidden')
        setMsg(resp.message);
        if (resp.status === 'success') {
          setMsgType('succcess');
          setTimeout(function(){
            window.location.reload();
          },1500)
        }
      })
      .catch((e) => {
        setLoader('hidden')
        setMsg(e);
        setMsgType('danger');
        console.log("Payment Error",e)
      });
  }

  return (
    <Container fluid className="my-3">
      <Loader loadmsg="please wait" visibility={loader} />
      <Row>
        <Col md={12}>
          {currentSubscription ?  <>
          <h3>Current Subscription</h3>
          
            <div className="card p-3">
              <h4>Plan Name : {currentSubscription.plan_name}</h4>
              <p><strong>Plan Type:</strong> {currentSubscription.plan_type}</p>
              <p><strong>Amount:</strong> ${currentSubscription.amount}</p>
              <p><strong>Credits:</strong> {currentSubscription.credits}</p>
              <Button variant="outline-primary" onClick={handleClick}>Upgrade Subscription</Button>
              {/* <br />
              <Button variant="outline-secondary" onClick={handleClick}>Downgrade Subscription</Button> */}

              <br />
              <Button variant="danger" onClick={()=>{CanCancelSubsciption(currentSubscription.subscriptionId)}}>Cancel Subscription</Button>
            </div>
            </>
          : <>
            <Button variant="outline-primary" onClick={handleClick}>View Plans</Button>
          </>
          }   
        </Col>
       
      </Row>
      <br />
      <PaymentTransaction />
      
      <Modal size="lg" show={showSubscriptionOptions} onHide={handleCloseOptionsModal} >
        <Modal.Header closeButton>
          <Modal.Title>Select New Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {plan.length === 0 ? (
            <p>No plans available.</p>
          ) : (
            <Row>
              {plan.map((item) => (
                <Col md={4} className="mt-3 mb-3 text-center">
                <div className="card p-3 redemption_code h-100">
                  <div className='text-center'><img src="https://bizhubb.salesorbit.io/assets/home/images/bizlink2-fav.png" className='w-25'/></div>
                  <br />
                 <strong>{item.plan_name}</strong>
                 <ul style={{"listStyle":"none","textAlign":"left","height":"150px"}}>
                    <li><b>Plan Type :</b> {item.plan_type}</li>
                    <li><b>Duration : </b>{item.duration}</li>
                    <li><b>Amount : </b>${item.amount}</li>
                    <li><b>No. of Credits : </b>{item.credits}</li>
                    {
                    item.virtual_bots > 0 && <>
                        <li><b>Virtual Bots : </b>{item.virtual_bots}</li>
                        <li><b>Profiles Per Bot : </b>{item.profiles_per_bot}</li>
                    </>    
                    }
                    
                 </ul>
                  <Col className="mt-1">
                    <Row className='justify-content-center'>
                       { currentSubscription && currentSubscription.id == item.id ? <Button onClick={()=>{CanCancelSubsciption(currentSubscription.subscriptionId)}}>Cancel Subscription</Button> : <Button variant="outline-primary" onClick={() => handleSelectPlan(item)} disabled ={selectedPlan && selectedPlan.id == item.id && "true"}>
                        {selectedPlan && selectedPlan.id == item.id  ? "Selected !" : "Select"}</Button> }
                    </Row>
                  </Col>
                </div>
              </Col>              
              ))}
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseOptionsModal}>Close</Button>
          {selectedPlan && <Button variant="primary" onClick={handleConfirmChange}>Confirm</Button>}
        </Modal.Footer>
      </Modal>

      {showConfirmPaymentModal && <Payment currentSubscription={currentSubscription} plan={selectedPlan} show={true} close={()=>{SetShowConfirmPaymentModal(false)}}/>}

    </Container>
  );
};

export default AffiliateSubscription;

const PaymentTransaction = ()=>{
  const [subcriptionList, setSubcriptionList] = useState([]);
  const [loader,setLoader] = useState("hidden");
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');


  const getSubscription = ()=>{
      var formdata = new FormData();
      formdata.append('app_name', 'NEXGEN');

      let request = {
        end_point: '/get-affiliate-subscription',
        token: localStorage.getItem("gs_token"),
        formdata: formdata,
      };
      setLoader('visible');
      POST_API(request)
        .then((resp) => {
          setLoader('hidden')
          if (resp.status === 'success') {
            setSubcriptionList(resp.data)
          } else {
            setSubcriptionList([]);
          }
        })
        .catch((e) => {
          setLoader('hidden')
          setMsg(e);
          setMsgType('danger');          
        });
  }

  useEffect(() => {
    getSubscription();
  }, []);

  return <>
    <Loader loadmsg="please wait" visibility={loader} />
    <Col md={12}>

    {msg !== '' ? (
      <FlashMess m={msg} mtype={msgType ? msgType : 'success'} />
    ) : (
      <></>
    )}
    
        {subcriptionList.length > 0 && <>
          <h3>Payment Transaction</h3>

          <table className='table table-bordered'>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Payment Date</th>
                  <th>Amount</th>
                  <th>Subscription ID</th>
                  <th>Is Recurring</th>
                  <th>Is Cancelled ?</th>
                </tr>
              </thead>
              <tbody>
                {subcriptionList.map((item,index)=>{
                     return (  
                          <tr>
                              <th>{++index}</th>
                              <th>{item.created_at}</th>
                              <th>${item.amount}</th>
                              <th>{item.subscriptionId}</th>
                              <th>Yes</th>
                              <th>{item.cancelled_at ? "Cancelled AT "+item.cancelled_at : "" }</th>
                            </tr>
                                );
                })}

              </tbody>
          </table>
        </> }
        </Col>

  </>
}

const Payment = (props) => {
  const [currentSubscription, setCurrentSubscription] = useState(props.currentSubscription);
  const [plan, setPlan] = useState(props.plan);
  const [proamount,setProAmount] = useState(0);
  const [actionType, setActionType] = useState('Regular'); // 'upgrade' or 'downgrade'
  const [msg, setMsg] = useState((currentSubscription && plan.amount == 0 ) ? 'You are moving to a free plan, so no refund or adjustment will be processed.' : "");
  const [msgType, setMsgType] = useState((currentSubscription && plan.amount == 0 ) ? "danger" : "");
  const [loader, setLoader] = useState('hidden');

  useEffect(() => {
    if(currentSubscription){
      getProratedAmount();
    }
  }, []);

  const getProratedAmount = ()=>{
    var formdata = new FormData();
    formdata.append('currentSubscription', currentSubscription);
    formdata.append('planID', plan.id);
    formdata.append('app_name', 'NEXGEN');
    
    let request = {
      end_point: '/get-prorated-amount',
      token: localStorage.getItem("gs_token"),
      formdata: formdata,
    };
    POST_API(request)
      .then((resp) => {
        setLoader('hidden');
        if (resp.status === 'success') {
          setProAmount(resp.data);
          setActionType(resp.data.actionType)
        } else {
          setActionType("Regular")
          setMsg(resp.message);
          setMsgType('danger');
          
        }
      })
      .catch((e) => {
        setActionType("Regular")
        setLoader('hidden');
        setMsg('Something Went Wrong,Please try again later.');
        setMsgType('danger');
      });
  }

  const handleFinalSubmit = (result) => {
   if(result.error){
    setMsg(result.error.message);
    setMsgType('danger');
    return;
   }
   setMsg("");
   setMsgType('danger');
    setLoader('visible');
    var formdata = new FormData();
    formdata.append('card_details', JSON.stringify(result));
    formdata.append('plan', plan.id);
    formdata.append('actionType', actionType);
    formdata.append('app_name', 'NEXGEN');
    let request = {
      end_point: '/purchase-affiliate-subscription',
      token: localStorage.getItem("gs_token"),
      formdata: formdata,
    };
    POST_API(request)
      .then((resp) => {
        setLoader('hidden');
        if (resp.status === 'success') {
          console.log('respsssspp', resp);
          setMsg(resp.message);
          setMsgType('succcess');
          setTimeout(function(){
          // window.location.reload();
          },1500);
        } else {
          setMsg(resp.message);
          setMsgType('danger');
        }
      })
      .catch((e) => {
        console.log("Payment Error",e)
        setLoader('hidden');
        setMsg('Something Went Wrong,Please try again later.');
        setMsgType('danger');
      });
}

  return (
    <div id="PaymentForm">
      <Modal
        show={props.show}
        backdrop="static"
        keyboard={false}
        onHide={props.close}
        id="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Loader loadmsg="please wait" visibility={loader} />
            {msg && <FlashMess m={msg} mtype={msgType ? msgType : 'success'} />}
            {currentSubscription &&
                    <div id="pricing-summary">
                      <h3>Pricing Details</h3>
                      <p>Current plan: ${currentSubscription.amount}/{currentSubscription.plan_type}</p>
                      <p>New plan: ${plan?.amount}/{plan?.plan_type}</p>

                      {proamount.prorated_amount > 0 &&
                        (
                          actionType == "upgrade" ? <>
                          <p>Amount to be charged for the remaining days this month: ${ proamount.prorated_amount }</p>
                          <p>Regular amount for subsequent months: ${ proamount.new_price_amount}</p> 
                          </> :
                           <>
                            <p>Credit for the unused portion of the current plan: ${proamount.prorated_amount}</p>
                            <p>New regular amount for the downgraded plan starting next billing cycle: ${proamount.new_price_amount}</p>
                          </>
                        )
                     }
                    </div>
            }
            <Elements stripe={stripePromise}>
              <CheckoutForm amount={proamount.prorated_amount > 0  && actionType == "upgrade" ?  proamount.prorated_amount : actionType == "downgrade" ? 0 : plan?.amount} handleFinalSubmit={handleFinalSubmit} setLoader={setLoader} close={props.close} />
            </Elements>
        </Modal.Body>
      </Modal>
    </div>
    
  );
};

const CheckoutForm = (props) => {

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    props.setLoader('visible');
    stripe.createToken(elements.getElement(CardElement)).then((result) => {
      props.setLoader('hidden');
      console.log("ffffffffffffffffffffffffsubtttt", result);
      props.handleFinalSubmit(result)
    });
    props.setLoader('hidden');
  };
  return (
    <form onSubmit={handleSubmit}>
      <CardElement />

      <Form.Row className='mt-3'>
          <Form.Group controlId="formBasicCardNo" className="pr-0" as={Col}>
            <Form.Label>Pay Amount (<strong>${props.amount}</strong>)</Form.Label>
          </Form.Group>
        </Form.Row>
        <Modal.Footer>
      <Button
              variant="primary"
              onClick={props.close}
              className="btn btn-primary btn-md tabformbutton mr-1"
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              type="submit"
              className="btn btn-primary btn-md tabformbutton"
            >
              Pay
            </Button>
            </Modal.Footer>
    </form>
  );
};
