import React, { useState, useEffect } from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import { FlashMess, POST_API, Loader } from '../Helpers/Helpers';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { Elements,CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {STRIPE_KEY} from "../Config";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(STRIPE_KEY);


const OrganizationTransactionHistory = () => {
  const [transaction,setTransaction] = useState([]);
  const [showTable,setShowTable] = useState(false);
  const [credits,setCredits] = useState(0);
  const [showAmounts,setShowAmounts] = useState(0);
  const [paymentModal, setPaymentModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState("hidden");
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  
  
  useEffect(() => {
    getHistory();
  }, []);


  const getHistory = () => {
    var formdata = new FormData();
    let request = {
      end_point: '/salesorbit-credits-transactions',
      token: localStorage.getItem("gs_token"),
      formdata: formdata,
    };
    setLoader('visible');
    POST_API(request)
      .then((resp) => {
        setLoader('hidden');
        if (resp.status === 'success') {
          setTransaction(resp.data);
          setShowTable(true)
        } else {
            setTransaction([]);
            setShowTable(false)
        }
      })
      .catch((e) => {
        setLoader('hidden');
        setMsg("Credit Transaction : " +e);
        setMsgType('danger');

      });
}

const handleClick = async () => {
   setShowModal(true);
}

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlePayment = async () => {
    if(localStorage.getItem("roleID") == 11){

    setLoader('visible');
    var formdata = new FormData();
    formdata.append('credits', credits);
    formdata.append('app_name', 'NEXGEN');
    formdata.append('gs_token', localStorage.getItem('gs_token'));
    let request = {
      end_point: '/request-credits',
      token: localStorage.getItem("gs_token"),
      formdata: formdata,
    };
    POST_API(request)
      .then((resp) => {
        setLoader('hidden');
        if (resp.status === 'success') {
          console.log('respsssspp', resp);
          setMsg(resp.message);
          setMsgType('succcess');
          setTimeout(function(){
           window.location.reload();
          },1500);
        } else {
          setMsg(resp.message);
          setMsgType('danger');
        }
      })
      .catch((e) => {
        console.log("Payment Error",e)
        setLoader('hidden');
        setMsg('Something Went Wrong,Please try again later.');
        setMsgType('danger');
      });

      return;
    }
    setShowModal(false);
    setPaymentModal(true);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    // Remove any non-numeric characters except for leading minus sign
    if (/^[0-9]*$/.test(value)) {
      setCredits(value);
      setShowAmounts((value * transaction.creditToCashRate).toFixed(2) );
    }
  };

  const handleKeyDown = (event) => {
    // Allow navigation keys, delete, backspace, tab, and enter
    const allowedKeys = [
      'Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter', 'Delete'
    ];

    if (allowedKeys.includes(event.key) || event.key === 'Control' || event.key === 'Shift') {
      return; // Allow these keys
    }

    // Prevent non-numeric keys
    if (event.key < '0' || event.key > '9') {
      event.preventDefault();
    }
  };

  return (
    <Container fluid className="my-3">
      <Loader loadmsg="please wait" visibility={loader} />
      <Row>
        <Col md={12}>
          <h3>Credit Transaction History</h3>
          <p>Current Available Credits : {transaction?.totalCredits}
            <a href="javascript:void(0)" onClick={handleClick} className='float-right btn btn-primary' >{ localStorage.getItem("roleID") == 11 ? "Request Credit" : "Add More Credits"}</a>
          </p>
          <br />
          {msg !== '' ? (
    <FlashMess m={msg} mtype={msgType ? msgType : 'success'} />
  ) : (
    <></>
  )}

                  <Row>
            </Row> 
        </Col>
        {showTable && <CreditTransaction txn={transaction.items} /> }
      </Row>
      <Modal show={showModal} onHide={handleCloseModal}>
        <FlashMess m={msg} mtype={msgType ? msgType : 'success'} />
        <Modal.Header closeButton>
          <Modal.Title>Please enter Number of credits you wants</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="number" value={credits} className="form-control" step="1" min="10"  
          onChange={handleChange}
          onKeyDown={handleKeyDown}
            />
          {localStorage.getItem("roleID") != 11 && <span>Amount to be Pay :${showAmounts}</span> }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handlePayment}> {localStorage.getItem("roleID") != 11 ? "Pay Now" : "Request" }</Button>
        </Modal.Footer>
      </Modal>

      {paymentModal && <Payment credits={credits} showAmounts={showAmounts} show={true} close={()=>{setPaymentModal(false)}}/>}
    </Container>
  );
};

export default OrganizationTransactionHistory;

const CreditTransaction = (props)=>{
  const [txnData,setTxnData] = useState(props.txn);
 
  return <>
   
    <Col md={12}>

        {txnData && txnData.length > 0 && <>
          <table className='table table-bordered table-responsive'>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Date</th>
                  <th>Credit Amount</th>
                  <th>Organization</th>
                  <th>Type</th>
                  <th>User Name</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>
                {txnData.map((item,index)=>{
                     return (  
                          <tr key={index}>
                              <td>{++index}</td>
                              <td>{item.createdAt}</td>
                              <td>{item.amount}</td>
                              <td>{item?.toOrganization.name}</td>
                              <td>{item.type == "ORG_TO_USER" ? "Sent to user" : "SELF"}</td>
                              <td>{item?.toUser?.name ? "Sent to : "+ item?.toUser?.name +" "+item?.toUser?.email: ""}</td>
                              <td>{item.comments}</td>
                            </tr>
                          );
                })}

              </tbody>
          </table>
        </> }
        </Col>

  </>
}

const Payment = (props) => {
  const [credits, setCredits] = useState(props.credits);
  const [amount, setAmount] = useState(props.showAmounts);
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  const [loader, setLoader] = useState('hidden');

  const handleFinalSubmit = (result) => {
   if(result.error){
    setMsg(result.error.message);
    setMsgType('danger');
    return;
   }
   setMsg("");
   setMsgType('danger');
    setLoader('visible');
    var formdata = new FormData();
    formdata.append('card_details', JSON.stringify(result));
    formdata.append('amount', amount);
    formdata.append('credits', credits);
    formdata.append('app_name', 'NEXGEN');
    formdata.append('gs_token', localStorage.getItem('gs_token'));
    let request = {
      end_point: '/purchase-credits',
      token: localStorage.getItem("gs_token"),
      formdata: formdata,
    };
    POST_API(request)
      .then((resp) => {
        setLoader('hidden');
        if (resp.status === 'success') {
          console.log('respsssspp', resp);
          setMsg(resp.message);
          setMsgType('succcess');
          setTimeout(function(){
           window.location.reload();
          },1500);
        } else {
          setMsg(resp.message);
          setMsgType('danger');
        }
      })
      .catch((e) => {
        console.log("Payment Error",e)
        setLoader('hidden');
        setMsg('Something Went Wrong,Please try again later.');
        setMsgType('danger');
      });
}

  return (
    <div id="PaymentForm">
      <Modal
        show={props.show}
        backdrop="static"
        keyboard={false}
        onHide={props.close}
        id="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Loader loadmsg="please wait" visibility={loader} />
            {msg && <FlashMess m={msg} mtype={msgType ? msgType : 'success'} />}
            <Elements stripe={stripePromise}>
              <CheckoutForm amount={amount} handleFinalSubmit={handleFinalSubmit} setLoader={setLoader} close={props.close} />
            </Elements>
        </Modal.Body>
      </Modal>
    </div>
    
  );
};

const CheckoutForm = (props) => {

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    props.setLoader('visible');
    stripe.createToken(elements.getElement(CardElement)).then((result) => {
      props.setLoader('hidden');
      console.log("ffffffffffffffffffffffffsubtttt", result);
      props.handleFinalSubmit(result)
    });
    props.setLoader('hidden');
  };
  return (
    <form onSubmit={handleSubmit}>
      <CardElement />

      <Form.Row className='mt-3'>
          <Form.Group controlId="formBasicCardNo" className="pr-0" as={Col}>
            <Form.Label>Pay Amount (<strong>${props.amount}</strong>)</Form.Label>
          </Form.Group>
        </Form.Row>
        <Modal.Footer>
      <Button
              variant="primary"
              onClick={props.close}
              className="btn btn-primary btn-md tabformbutton mr-1"
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              type="submit"
              className="btn btn-primary btn-md tabformbutton"
            >
              Pay
            </Button>
            </Modal.Footer>
    </form>
  );
};
